import { USAGE_LOCATION } from '@/types/language-data.js';
import { ObjectPoolData } from '@/apiConnections/object-pool-data.js';

export async function openResource(resource: USAGE_LOCATION, id: string | number) {
  switch (resource) {
    case USAGE_LOCATION.PARAMETERS:
      id = (await ObjectPoolData.getParameter(+id, { fields: 'key' })).key;
      window.open(`${import.meta.env.VITE_OWB_URL}/parameters/${id}`, '_blank');
      break;
    case USAGE_LOCATION.OBJECTS:
      window.open(`${import.meta.env.VITE_OWB_URL}/objects/${id}`, '_blank');
      break;
    case USAGE_LOCATION.CONNECTIONS:
      window.open(`${import.meta.env.VITE_OWB_URL}/connections/${id}`, '_blank');
      break;
    case USAGE_LOCATION.CATEGORIES:
      window.open(`${import.meta.env.VITE_CDM_URL}/category/${id}`, '_blank');
      break;
    case USAGE_LOCATION.FILTERS:
      window.open(`${import.meta.env.VITE_CDM_URL}/filter/${id}`, '_blank');
      break;
  }
}
