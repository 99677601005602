import textLocalization from '@/apiConnections/text-localization';
import { LANGUAGE_KEY } from '@/types/localization';

type Rule = (id: string) => true | string | Promise<true | string>;

export class IdValidator {
  constructor(
    public source: LANGUAGE_KEY,
    public target: LANGUAGE_KEY,
  ) {}

  rules: Rule[] = [
    (id: string) => {
      return this.hasLength(id) || 'Language-ID ist erforderlich.';
    },
    async (id: string) => {
      return !(await this.existsAlready(id)) || 'Für diese ID ist bereits ein Eintrag vorhanden.';
    },
    async (id: string) => {
      return (await this.hasTranslations(id)) || 'Sprachreferenzen dürfen nicht neben Übersetzungen liegen.';
    },
  ];

  public async validate(appId: string, langId: string) {
    const errors: string[] = [];
    for (const rule of this.rules) {
      const result = await rule(`${appId}/${langId}`);
      if (result && typeof result === 'string') {
        errors.push(result);
      }
    }
    return errors;
  }

  private hasLength(id: string) {
    // id is passed as appId/langId/...
    const [, langId] = id.trim().split('/');
    return !!langId;
  }

  private async existsAlready(langId: string) {
    if (!this.hasLength(langId)) return false;
    const langRef = await textLocalization.getText(langId, this.source, this.target);
    if (!langRef) return false;
    const keys = Object.keys(langRef);
    return keys.length > 0 && keys.includes(LANGUAGE_KEY.GERMAN);
  }
  private async hasTranslations(langId: string) {
    if (!this.hasLength(langId)) return true;
    const splitId = langId.split('/');
    const parentLevelId = splitId.slice(0, -1).join('/');
    const parentRef = await textLocalization.getText(parentLevelId, this.source, this.target);
    const keys = Object.keys(parentRef);
    return keys.length === 0 || !keys.includes(LANGUAGE_KEY.GERMAN);
  }
}
