import axios from 'axios';
import { useKeycloak } from '@/plugins/keycloak.js';
import type { LANGUAGE_KEY } from '@/types/localization.js';
import { type Localization, type LocalizationServiceResponse } from '@/types/localization.js';

const textLocalization = {
  tokenHeader: { headers: { Authorization: '' } },
  textLocalizationUrl: import.meta.env.VITE_TEXT_LOCALIZATION_URL as string,

  refreshBearerToken() {
    this.tokenHeader.headers.Authorization = `Bearer ${useKeycloak().token}`;
  },

  async getAppId(appId: string, sourceLang: LANGUAGE_KEY, targetLang: LANGUAGE_KEY, excludeFromTranslation?: boolean) {
    function isLocalization(data: object): data is Localization {
      // very rudimentary type guard because here data can only be Record<string, string | Record<...>>
      return Object.values(data).some((value) => typeof value === 'string');
    }
    function reduceToSelectedLanguages(data: LocalizationServiceResponse) {
      return Object.entries(data).reduce(
        (acc, [key, value]) => {
          if (isLocalization(value)) {
            acc[key] = {
              [sourceLang]: value[sourceLang],
              [targetLang]: value[targetLang],
            };
            if (value.excludeFromTranslation !== undefined) {
              acc[key].excludeFromTranslation = value.excludeFromTranslation;
            }
          } else {
            acc[key] = reduceToSelectedLanguages(value);
          }
          return acc;
        },
        {} as Record<string, Localization>,
      );
    }
    let url = `${this.textLocalizationUrl}/${appId}`;
    if (excludeFromTranslation !== undefined) {
      url += `?exclude-from-translation=${excludeFromTranslation}`;
    }
    return axios.get(url).then((response) => {
      return reduceToSelectedLanguages(response.data);
    });
  },

  async getAppIdList() {
    return axios.get(`${this.textLocalizationUrl}/?lang=de`).then((response) => Object.keys(response.data));
  },

  async getText(id: string, sourceLang: string, targetLang: string) {
    return axios
      .get(`${this.textLocalizationUrl}/texts?source=${sourceLang}&target=${targetLang}&path=${id}`, {
        headers: { Accept: 'application/json' },
      })
      .then(({ data }) => {
        if (Object.keys(data).length === 0) return {};

        const keys = id.split('/');
        return keys.reduce((nestingLevel, key) => {
          return nestingLevel[key];
        }, data);
      });
  },

  async getSelected(paths: { appId: string; subPaths: string[] }, sourceLang: string, targetLang: string) {
    const combinedPaths = paths.subPaths.map((subPath) => `${paths.appId}/${subPath}`);

    return axios
      .post(
        `${this.textLocalizationUrl}/texts?source=${sourceLang}&target=${targetLang}`,
        { paths: combinedPaths },
        {
          headers: { accept: 'application/xml' },
        },
      )
      .then((response) => response.data);
  },

  async updateSubDoc(route: string, updatedObject: Localization) {
    this.refreshBearerToken();
    return axios.patch(`${this.textLocalizationUrl}/${route}`, updatedObject, this.tokenHeader);
  },

  async addSubDoc(route: string, updatedObject: Localization) {
    this.refreshBearerToken();
    return axios.put(`${this.textLocalizationUrl}/${route}`, updatedObject, this.tokenHeader);
  },

  async updateFromXML(xml: string | ArrayBuffer | null) {
    this.refreshBearerToken();
    return axios.patch(`${this.textLocalizationUrl}/texts`, xml, {
      headers: { 'content-type': 'application/xml', ...this.tokenHeader.headers },
    });
  },
};

export default textLocalization;
