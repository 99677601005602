<script setup lang="ts">
import TranslatorHeader from '@/components/Header/TranslatorHeader.vue';
import ToastMessages from '@/components/ToastMessages/ToastMessages.vue';
</script>

<template>
  <v-app>
    <v-main>
      <TranslatorHeader class="item-head" :header-title="'Translator'" />
      <router-view></router-view>
    </v-main>

    <ToastMessages />
  </v-app>
</template>

<style lang="scss" scoped>
.item-head {
  width: 100%;
  height: auto;
  position: initial;
  left: initial;
  -webkit-transform: none;
  transform: none;
}
</style>
